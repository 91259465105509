<script setup>
import { reactive, inject/*, onMounted, watchEffect*/ } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { useNavStore } from './../stores/nav'
import Loading from './../components/Loading.vue'
import { getProducts } from './../services/woo'

const quotationStore = useQuotationStore();

const navStore = useNavStore();
navStore.$pause();

const { formatPrice } = inject('formatPrice')
const { filterDatas } = inject('filterDatas')
const { config } = inject('config')


const state = reactive({
  service : false,
  loading : {
    message : 'Chargement en cours, merci de patienter',
    status : true,
  },
  essences : [],
});


getProducts(
  config.services.essences.tagId, 
  config.wooId, 
  config.wooPass
).then((res) => {
  if (res.data && res.data.status != 200) {
    state.loading = {
      message : res.message,
      status : false
    };
  } else {
    state.service = true;
    state.essences = filterDatas(res, false, false, false);
  }
  navStore.$resume();
  navStore.$restart(true);
});

const validate = (essenceId, essenceName, essenceTags, essenceGamme, essencePrice, essenceImg, essenceDescription) => {
  quotationStore.$setEssence(essenceId, essenceName, essenceTags, essenceGamme, essencePrice, essenceImg, essenceDescription);
  // console.log('Essence.vue', state.essences);
}
</script>

<template>
  <div class="page-content">
    <div class="page-title">
      <h1>Essence de bois</h1>
      <h2>Choisissez votre essence de bois :</h2>
    </div>

    <h3>Lames bois exotique</h3>
    <div v-if="state.service == false">
      <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
    </div>

    <div class="essence__list">
      <div class="essence__item" v-for="essence in state.essences.filter(essence => essence.gamme == 'EXO' || essence.gamme == 'ITAUBA')" :key="essence.id">
        <div @click="validate(essence.id,essence.name,essence.tags,essence.gamme,essence.price,essence.images.src,essence.description)" >
          <div class="essence__img" v-if="essence.images != undefined">
              <img :src="essence.images.src" :class="{ 'essence__item_active': essence.id === quotationStore._essenceId }" />
          </div>
          <div class="essence__img" v-else>
            No Pic
          </div>
          <div class="essence__price">
            {{ formatPrice(essence.price) }} €
            <span v-if="essence.unite == 'm2'">/m2</span>
            <span v-else-if="essence.unite == 'ml'">/ml</span>
          </div>
          <div class="essence__title">
            {{ essence.name }}
          </div>
        </div>
      </div>
    </div>

    <h3>Lames PIN</h3>
    <div v-if="state.service == false">
      <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
    </div>

    <div class="essence__list">
      <div class="essence__item" v-for="essence in state.essences.filter(essence => essence.gamme == 'PIN')" :key="essence.id">
        <div @click="validate(essence.id,essence.name,essence.tags,essence.gamme,essence.price,essence.images.src,essence.description)" >
          <div class="essence__img" v-if="essence.images != undefined">
              <img :src="essence.images.src" :class="{ 'essence__item_active': essence.id === quotationStore._essenceId }" />
          </div>
          <div class="essence__img" v-else>
            No Pic
          </div>
          <div class="essence__price">
            {{ formatPrice(essence.price) }} €
            <span v-if="essence.unite == 'm2'">/m2</span>
            <span v-else-if="essence.unite == 'ml'">/ml</span>
          </div>
          <div class="essence__title">
            {{ essence.name }}
          </div>
        </div>
      </div>
    </div>

    <h3>Lames concept GRAD</h3>
    <div v-if="state.service == false">
      <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
    </div>

    <div class="essence__list">
      <div class="essence__item" v-for="essence in state.essences.filter(essence => essence.gamme == 'GRAD')" :key="essence.id">
        <div @click="validate(essence.id,essence.name,essence.tags,essence.gamme,essence.price,essence.images.src,essence.description)" >
          <div class="essence__img" v-if="essence.images != undefined">
              <img :src="essence.images.src" :class="{ 'essence__item_active': essence.id === quotationStore._essenceId }" />
          </div>
          <div class="essence__img" v-else>
            No Pic
          </div>
          <div class="essence__price">
            {{ formatPrice(essence.price) }} €
            <span v-if="essence.unite == 'm2'">/m2</span>
            <span v-else-if="essence.unite == 'ml'">/ml</span>
          </div>
          <div class="essence__title">
            {{ essence.name }}
          </div>
        </div>
      </div>
    </div>

      <h3>Lames TIMBERTECH</h3>
      <div v-if="state.service == false">
        <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
      </div>
      <div class="essence__list">
        <div class="essence__item" v-for="essence in state.essences.filter(essence => essence.gamme == 'TIMBERTECH')" :key="essence.id">
          <div @click="validate(essence.id,essence.name,essence.tags,essence.gamme,essence.price,essence.images.src,essence.description)" >
            <div class="essence__img" v-if="essence.images != undefined">
                <img :src="essence.images.src" :class="{ 'essence__item_active': essence.id === quotationStore._essenceId }" />
            </div>
            <div class="essence__img" v-else>
              No Pic
            </div>
            <div class="essence__price">
              {{ formatPrice(essence.price) }} €
              <span v-if="essence.unite == 'm2'">/m2</span>
              <span v-else-if="essence.unite == 'ml'">/ml</span>
            </div>
            <div class="essence__title">
              {{ essence.name }}
            </div>
          </div>
        </div>
      </div>

    <div class="nav">
      <button class="mdc-button mdc-button--raised mdc-button--primary" @click="navStore.$prev">Précédent</button>
      <button class="mdc-button mdc-button--raised mdc-button--primary" :class="{ 'disabled': quotationStore._essenceId == 0 }" @click="navStore.$next">Suivant</button>
    </div>

    <!-- <div class="recap">
      <h2>Votre sélection</h2>
      <strong>Surface</strong> : {{ quotationStore._surface }}m2 - <strong>Type </strong>: {{ quotationStore._type }} {{ state.getProduct }}
    </div> -->
  </div>
</template>

<style scoped>

h3 {
  font-size: 1.5em;
  font-weight: normal;
  margin-top: 20px;
  text-align: left;
  background-color: #efab23;
  display: table;
  padding: 5px 20px;
  color: #ffff;
  font-weight: bold;
}

.essence__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 50px;
}

.essence__item{
  width: 14%;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 0px;
  transition: all 0.3s;
  text-align: center;
  margin: 10px;
}

.essence__img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: #eeeeee 4px solid;
}

.essence__title {
    font-size: 1em;
    font-weight: bold;
    text-align: justify;
    padding: 15px;
  }

.essence__price {
  padding: 0px;
  background-color: #efab23;
  color: #ffff;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  width: 120px;
  margin: auto;
}

.essence__price {
  font-size: .8em;
}

.essence__img img.essence__item_active {
  border: #efab23 4px solid;
}

@media (max-width: 1024px) {
  .essence__item {
    width: 22%;
  }

  .essence__title {
    font-size: 0.8em!important;
  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 1em;
  }

  .essence__item {
    width: 42%;
  }

  .essence__price {
    font-size: 0.8em;
  }
}
</style>

<script setup>
import { reactive, inject/*, onMounted, watchEffect*/ } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { useNavStore } from './../stores/nav'
import Loading from './../components/Loading.vue'
import { getProducts } from './../services/woo'

const quotationStore = useQuotationStore();

const navStore = useNavStore();
navStore.$pause();

const { emitCustomEvent } = inject('cancelBomb')
const { formatPrice } = inject('formatPrice')
const { filterDatas } = inject('filterDatas')
const { config } = inject('config')

const stateStructure = reactive({
  service : false,
  loading : {
    message : 'Chargement des structures, merci de patienter',
    status : true,
  },
  datas : [],
});

const stateFixation = reactive({
  service : false,
  loading : {
    message : 'Chargement des fixations, merci de patienter',
    status : true,
  },
  datas : [],
});

getProducts(
  config.services.structures.tagId, 
  config.wooId, 
  config.wooPass  
).then((res) => {
  if (res.data && res.data.status != 200) {
    stateStructure.loading = {
      message : res.message,
      status : false
    };
  } else {
    stateStructure.service = true;
    stateStructure.datas = filterDatas(res, quotationStore._essenceGamme, true, false);
    if (stateStructure.datas.length == 0) {
      quotationStore.$setStructure(-1, '', [], '');
      navStore.$next();
    }
  }
  navStore.$resume();
  navStore.$restart(true);
});

getProducts(
  config.services.fixations.tagId, 
  config.wooId, 
  config.wooPass  
).then((res) => {
  if (res.data && res.data.status != 200) {
    stateFixation.loading = {
      message : res.message,
      status : false
    };
  } else {
    stateFixation.service = true;
    stateFixation.datas = filterDatas(res, quotationStore._essenceGamme, true, false);
    if (stateFixation.datas.length == 0) {
      quotationStore.$setFixation(-1, '', [], '');
      navStore.$next();
    }
  }
  navStore.$resume();
  navStore.$restart(true);
});

const validateStructure = (dataId, dataName, dataTags, dataGamme, dataPrice, dataImg, dataDescription) => {
  quotationStore.$setStructure(dataId, dataName, dataTags, dataGamme, dataPrice, dataImg, dataDescription);
}

const validateFixation = (dataId, dataName, dataTags, dataGamme, dataPrice, dataImg, dataDescription) => {
  quotationStore.$setFixation(dataId, dataName, dataTags, dataGamme, dataPrice, dataImg, dataDescription);
}
</script>

<template>
  <div class="page-content">
    <div class="page-title">
      <h1>Supports & fixations</h1>
    </div>
    <div v-if="stateStructure.service == false">
      <Loading v-bind="{'msg': stateStructure.loading.message, 'status': stateStructure.loading.status}" />
    </div>
    <div @click="emitCustomEvent(this)" v-else>
      <h2>Choisissez votre structure : </h2>
      <div class="grid">
        <Slide v-for="data in stateStructure.datas" :key="data">
          <div class="grid__item" @click="validateStructure(data.id,data.name,data.tags, data.gamme, data.price, data.images.src, data.description)" >
            <div class="grid_img" v-if="data.images != undefined">
                <img :src="data.images.src" :class="{ 'grid__item_active': data.id === quotationStore._structureId }" />
            </div>
            <div class="grid_img already" @click="validateStructure(0, 'J\'ai déjà ma structure', [], '', 0, '')" v-else-if="data.image_already == true">
                <img src="./../assets/already.png" :class="{ 'grid__item_active': quotationStore._structureId === 0 }" />
            </div>
            <div class="grid_img" v-else>
              No Pic
            </div>
            <div class="grid_price" v-if="data.price">
              {{ formatPrice(data.price) }} €
              <span v-if="data.unite == 'm2'">/m2</span>
              <span v-else-if="data.unite == 'ml'">/ml</span>
            </div>
            <div class="grid_title" v-if="data.name">
              {{ data.name }}
            </div>
            <div class="grid_title" v-else>
              J'ai déjà ma structure
            </div>
            <!-- <div class="favorite" v-if="data.favorite">
                <div class="badge">Recommandé par Deckibois</div>
            </div> -->
          </div>
        </Slide>
      </div>
  
      <div v-if="stateFixation.service == false">
        <Loading v-bind="{'msg': stateFixation.loading.message, 'status': stateFixation.loading.status}" />
      </div>
  
      <h2>Choisissez vos fixations : </h2>
      <div class="grid">
        <Slide v-for="data in stateFixation.datas" :key="data">
          <div class="grid__item" @click="validateFixation(data.id,data.name,data.tags, data.gamme, data.price, data.images.src, data.description)" >
            <div class="grid_img" v-if="data.images != undefined">
                <img :src="data.images.src" :class="{ 'grid__item_active': data.id === quotationStore._fixationId }" />
            </div>
            <div class="grid_img already" @click="validateFixation(0, 'J\'ai déjà mes fixations', [], '', 0, '')" v-else-if="data.image_already == true">
                <img src="./../assets/already.png" :class="{ 'grid__item_active': quotationStore._fixationId === 0 }" />
            </div>
            <div class="grid_img" v-else>
              No Pic
            </div>
            <div class="grid_price" v-if="data.price">
              {{ formatPrice(data.price) }} €
              <span v-if="data.unite == 'm2'">/m2</span>
              <span v-else-if="data.unite == 'ml'">/ml</span>
            </div>
            <div class="grid_title" v-if="data.name">
              {{ data.name }}
            </div>
            <div class="grid_title" v-else>
              J'ai déjà mes fixations
            </div>
            <div class="badge_container" v-if="data.favorite">
                <div class="badge">Le choix Deckibois</div>
            </div>
            <!-- <div class="favorite" v-if="data.favorite">
                <div class="badge">Recommandé par Deckibois</div>
            </div> -->
          </div>
        </Slide>
      </div>
  
  
    <div class="nav">
      <button class="mdc-button mdc-button--raised mdc-button--primary" @click="navStore.$prev">Précédent</button>
      <button class="mdc-button mdc-button--raised mdc-button--primary" :class="{ 'disabled': quotationStore._structureId == undefined }" @click="navStore.$next">Suivant</button>
    </div>
  
    <!-- <div class="recap">
      <h2>Votre sélection</h2>
        <strong>Surface</strong> : {{ quotationStore._surface }}m2 - <strong>Type </strong>: {{ quotationStore._type }} {{ state.getProduct }}<br />
        <strong>Essence</strong>: {{ quotationStore._essenceName }} - <strong>Gamme</strong>: {{ quotationStore._essenceGamme }}
    </div> -->
  </div>
  </div>
</template>

<style scoped>

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: start;
}

.carousel__slide {
  width: 14%!important;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px 10px;
}

.grid__item {
  padding: 0px;
  width: 100%;
}

.grid_title {
  padding: 10px 5px;
  text-align: center;
  font-size: 1em;
}

.grid_price {
  font-size: 0.8em;
  padding: 0px;
  background-color: #efab23;
  color: #ffff;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  width: 100px;
  margin: 10px auto 0px auto;
}

.grid_img img {
  width: 100%;
  border: #eeeeee 4px solid;
  max-height: 210px;
  height: 210px;
  object-fit: cover;
}

.grid_img img.grid__item_active {
  border: #efab23 4px solid;
}

.grid_img.already {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.grid_img.already img {
  width: 100%;
  border: #eeeeee 4px solid;
  max-height: 210px;
  height: 210px;
  object-fit: cover;
}

.grid_img.already img.grid__item_active {
  border: #efab23 4px solid;
}

.badge_container {
    overflow: hidden;
    top: 0px;
    left: 0px;
    width: 118px;
    height: 115px;
    position: absolute;
    padding: 0;
}

.badge {
  position: absolute;
  top: 36px;
  background-color: #efab23;
  transform: rotate(-45deg);
  width: 155px;
  left: -35px;
  padding: 4px;
  font-size: 0.8em;
  color: #ffff;
  font-weight: bold;
}
.badge:before {
    left: 0;
}

.badge:after {
    right: 5px;
}

@media (max-width: 1200px) {
  .carousel__slide {
    width: 30%!important;
  }
}

@media (max-width: 1024px) {
  .carousel__slide {
    width: 21%!important;
  }

  .grid_title {
    height: auto !important;
    padding: 40 20px !important;
  }
}

@media (max-width: 768px) {
  .carousel__slide {
    width: 42%!important;
  }

  .grid_img img {
    max-height: 150px;
    height: 150px;
  }

  .grid_img.already img {
    max-height: 150px;
    height: 150px;
  }
}
</style>
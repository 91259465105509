<script setup>
import { reactive, onMounted, watchEffect, provide } from 'vue';
import { useNavStore } from './../stores/nav.js';
import Step1 from './Surface.vue'
import Step2 from './Essence.vue'
import Step3 from './Structure.vue'
import Step4 from './Accessoires.vue'
import Step5 from './User.vue'
import Step6 from './Devis.vue'

const navStore = useNavStore();

/*const cancelBomb = () => {
  console.log('cancelVBomb');
  navStore.restartBomb = true
}*/

let state = reactive({
  step : 1
})

onMounted(() => {
  watchEffect(async() => {
     state.step = navStore.step;
  })
})

const emitCustomEvent = (/*self*/) => {
  /*self.$emit('customEventCancelBomb', true);*/
  navStore.restartBomb = true
  //<button @click="emitCustomEvent(this)">OK</button>
}


const formatPrice = (price) => {
  return parseFloat(price).toFixed(2);
}

// get data from meta data array
const getUnite = (meta_data, dataName) => {
  const filterObj = meta_data.filter((data) => data.key === dataName);
  return filterObj;
}

const filterDatas = (datas, gamme, already, typeSol) => {
  
  let datasFiltered = [];
  
  datas.forEach((data) => {
    let tagsFiltered = [],
      gammeMatch = false,
      gammeName = '',
      favoriteTmp = [],
      favorite = false,
      uniteArray = getUnite(data.meta_data, '_custom_unit'),
      // if more than 1 value, take first by default, to be safe;
      unite = uniteArray ? uniteArray[0].value : '', //'m2',
      typeSolMatch = false,
      description = data.description ? data.description : '';
    data['tags'].forEach((tag) => {
      if ((tag.name).substring(0,5).toUpperCase() == 'GAMME') {
        tagsFiltered.push({
          'id' : tag.id,
          'name' : tag.name
        });
      }
      if ((tag.name).substring(0,6).toUpperCase() == 'FAVORI') {
        favoriteTmp.push({
          'id' : tag.id,
          'name' : tag.name
        });
      }
      if (typeSol !== false) {
        if ((tag.name).toUpperCase() == typeSol.toUpperCase()) {
          typeSolMatch = true;
        }
      }
      
      /*let uniteTmp = (tag.name).split('pour 1 m');
      if (uniteTmp[0] != tag.name) {
        //console.log('tag = ', tag.name);
        unite = '';
      }*/
      
    });
    
    if (gamme != '') {
      favoriteTmp.forEach((fav) => {
        let favorisNameTmp = (fav.name).toUpperCase().split(' ');
        if (favorisNameTmp[1] == gamme) {
          favorite = true;
        }
      });
    }
    
    tagsFiltered.forEach((tag)  => {
        let gammeNameTmp = (tag.name).toUpperCase().split(' ');
        if (gammeNameTmp[1] == gamme && gamme !== false) {
          gammeMatch = true;
          gammeName = gamme;
        } else {
          gammeName = gammeNameTmp[1];
        }
    });

    
    /*console.log("Gamme demandée = ", gamme, 'match avec prod = ', gammeMatch, 'gamme prod = ', gammeName);
    console.log('Type = ', typeSol, 'match avec prod = ', typeSolMatch);*/
    
    if (gammeMatch || gamme == false) {
      if (typeSol == false || typeSolMatch) {
        datasFiltered.push({
          'id' : data['id'],
          'images' : data['images'][0],
          'name' : data['name'],
          'price' : data['price'],
          'gamme' : gammeName,
          'tags' : tagsFiltered,
          'favorite' : favorite,
          'unite' : unite,
          'description' : description
        });
      }
    }
  });
  
  let datasFilteredWithFavoritesFirst = [];
  
  datasFiltered.forEach((data) => {
    if (data['favorite'] == true) {
      datasFilteredWithFavoritesFirst.push(data);
    }
  });
  datasFiltered.forEach((data) => {
    if (data['favorite'] == false) {
      datasFilteredWithFavoritesFirst.push(data);
    }
  });
  
  if (already && datasFilteredWithFavoritesFirst.length > 0) {
    datasFilteredWithFavoritesFirst.push({
      'id': 0,
      'image_already': true,
      'tags': [],
      'gamme': ''
    });
  }
  return datasFilteredWithFavoritesFirst; 
}

const handleSlideEvent = (self) => {
  emitCustomEvent(self);
}

provide('cancelBomb', {
  emitCustomEvent
})
provide('formatPrice', {
  formatPrice
})
provide('filterDatas', {
  filterDatas
})
provide('handleSlideEvent', {
  handleSlideEvent
})
</script>

<template>
  <div v-if="state.step == 1">
    <Step1 />
  </div>
  <div v-else-if="state.step == 2">
    <Step2 />
  </div>
  <div v-else-if="state.step == 3">
    <Step3 />
  </div>
  <div v-else-if="state.step == 4">
    <Step4 />
  </div>
  <div v-else-if="state.step == 5">
    <Step5 />
  </div>
  <div v-else-if="state.step == 6">
    <Step6 />
  </div>
</template>

<style>
  .recap {
    width: 100%;
    margin: auto;
    color: #2c3e50;
    padding: 30px 0 50px 0 !important;
    text-align: left;
  }

  .recap h2 {
    text-align: center;
  }

  .recap h2, .recap strong {
    color: #2c3e50 !important;
    text-transform: none;
    font-weight: bold;
  }
   /*.carousel {
    height: 600px;
    max-height: 650px;
  }*/
  .carousel__item {
    height: 100%;
    width: 100%;
    max-height: none;
    text-align: center;
    cursor: pointer
  }
  
  .carousel__item:hover {
    background-color: #fafafa;
  }
  
  .carousel__item_active {
    background-color: #e7ffde !important;
    border: 10px solid #64bc45 !important;
  }
  
  .carousel_img {
    min-height: 300px;
    max-height: none;
  }
  
  .carousel_img img {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1;
    max-height: inherit;
  }
  
  .carousel_title {
    height: 80px;
    padding: auto;
  }
  
  @media (max-width: 1024px) {
   .recap {
     margin: 0 !important;
     padding: 0 !important;
   } 
   .carousel__item {
    max-height: 300px !important;
   }
  .carousel_img {
    min-height: 100px !important;
    max-height: 202px !important;
  }
  .carousel_img img {
    width: auto !important;
    height: 100% !important;
    /*max-height: inherit;
    width: auto !important;
    height: 100% !important;
    max-width: 200px !important;*/
  }
  .carousel_title {
    /* see in each view */
  }
}

  .carousel_price {
    font-weight: 900;
  }
  
  .mdc-form-field, .mdc-text-field {
    margin-top: 10px !important;
  }
  
  /* Avoid Avia Thm conflicts */
  .entry-content-wrapper li {
    margin-left: 0 !important;
    padding : 0 !important;
  }
  #top .main_color input[type='text'] {
    border-color: white !important;
    background-color: white !important;
  }
  
  .carousel__prev, .carousel__next {
    background-color: #efab23!important;
    color: white !important;
    border-radius: 200px !important;
    width: 60px !important;
    height: 60px !important;
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #efab23 !important;
  }
  
  .carousel__prev:hover, .carousel__next:hover {
    opacity: 0.7 !important;
  }

</style>
